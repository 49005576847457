
<template>
  <div class="container">
    <div class="row header">
      <div class="col">
        <div class="title font-size-xl font-weight-bold mt-4">
          {{ $t("orders.show.title") }}
        </div>
      </div>
    </div>

    <order
        :order="order"
        @shop_rated="shop_rated($event)"
        @driver_rated="driver_rated($event)"
        @status_updated="status_updated($event)"
    />
  </div>
</template>

<script>
import {Promise} from "core-js";
import Order from "@/views/Orders/partials/Order";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "OrdersShow",
  components: {Order},
  setup(){
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("orders.show.page_title"),
    })
  },
  computed: {
    order_id: function () {
      return parseInt(this.$route.params.id);
    },
  },
  data(){
    return {
      order: null,
    }
  },
  watch: {
    order_id: {
      immediate: true,
      handler (new_order_id){
        if (!isNaN(new_order_id)) {
          //Reset data
          this.order = null;
          //Get order again
          this.getOrder(new_order_id).then((response) => {
            this.order = response;
          }, () => {
            //Redirect to 404
            this.$router.replace("/404");
          });
        }
      }
    }
  },
  methods: {
    async getOrder(order_id){
      return new Promise( (resolve, reject) => {
        let url = window.Routes.getRoute("orders_show", {
          order_id: order_id
        });

        window.axios.get(url).then(
            (response) => {
              resolve(response.data.data);
            },
            (error) => {
              reject(error.response.data.errors);
            }
        );
      })
    },
    shop_rated(response) {
      //Find all shops with this id, and modify mu_rate value
      this.order.shop.my_rating = {rating: response.rating.rating, description: response.rating.comment};
    },
    driver_rated(response) {
      //Find all shops with this id, and modify mu_rate value
      this.order.driver.my_rating = {rating: response.rating.rating, description: response.rating.comment};
    },
    status_updated(response){
      this.order.status = response;
    }
  }
};
</script>

<style>
</style>
